.header-container {
  display: flex;
  flex-direction: row;
  width: 95%;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
}

.logo {
  color: white;
  font-size: 1.5em
}

.header-right {
  color: white;
  padding: 0.3em 0.8em;
  border-radius: 8px;
}

.header-right:hover {
  background-color: rgba(190, 219, 191, 0.15)

}

/* button {
  background-color: rgba(51, 122, 54, 0.6);
  border: none;
  color: white;
  padding: 1em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: .9em;
  border-radius: 8px;
} */
