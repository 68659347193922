.contact-form-container {
  color: rgb(208, 211, 226);
  display: flex;
  justify-self: center;
  border-radius: 4px;
  /* background-color: rgba(7, 188, 12, 0.2); */
  background-color: rgba(90, 105, 90, 0.35);
  width: 60%;
  padding: 1em;
  max-width: 450px;
  text-align: left;
  font-size: 1em;
  margin-top: 2em;
}

form {
  width: 100%;
}

.quote {
  font-style: italic;
  font-size: 1.2em;
}

input {
  /* background-color: rgba(135, 218, 221, 0.4); */
  background-color: rgba(212, 235, 213, 0.05);
  border-radius: 8px;
  width: 100%;
  color: rgb(208, 211, 226);
  line-height: 2.2em;
  font-size: large;
  margin-bottom: 0.5em;
  /* border-color:rgba(190, 219, 191, 0.15); */
  border-bottom: white;
  border-width: 0px;
  font-family: 'PT Sans', sans-serif;
}

label {
  margin: 0.3em;
}

.bad-email {
  border-radius: 4px;
  border-color: red;
  border-width: 0.5em;
  color: red
}

.top-line-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

textarea {
    /* background-color: rgba(135, 218, 221, 0.4); */
    background-color: rgba(212, 235, 213, 0.05);
    border-radius: 8px;
    width: 100%;
    color: rgb(208, 211, 226);
    line-height: 2.2em;
    font-size: large;
    margin-bottom: 0.5em;
    /* border-color:rgba(190, 219, 191, 0.15); */
    border-bottom: white;
    border-width: 0px;
    font-family: 'PT Sans', sans-serif;
}

.top-line {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.form-bottom {
  display: flex;
  flex-direction: column;
}

#message-input-label {
  /* padding-bottom: 20vh; */
}

#message-input {
 height: 20vh;
}

#submit {
  width: 40%;
  border-width: 2px;
  border-color: rgba(30, 54, 30, 0.5);
  font-size: 1.1em;
}

#submit-container {
 display: flex;
 justify-content: center;
}
