.App {
    text-align: center;
    font-family: 'PT Sans', sans-serif;
    height: 100vh;
}
/* .particles-container {

} */

#sub-header {
    font-style: italic;
    font-size: 1em;
    color: white;
    display: flex;
    align-self: flex-start;
    margin-left: 2.2em;
  }

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: -1;
    /* background-position: 50% 50%; */
}
