.center-container {
  /* padding: 20% 2em; */
  /* display: block; */
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  font-size: 2em;
  color: white;
}
